import React from 'react';
import s from './RegisterPage.module.css';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import AuthForm from '../AuthForm/AuthForm';
import {FieldValues} from "react-hook-form";
import {registerRequest} from "../../features/user/userSlice";
import {selectErrors, selectLoading} from "../../features/user/selectors";

const RegisterPage = () => {
  const dispatch = useAppDispatch();
  const errors = useAppSelector(selectErrors);
  const loading = useAppSelector(selectLoading);

  const onSubmit = ({ email, name, password }: FieldValues) => {
    dispatch(registerRequest({name, email, password}))
  };

  return (
    <div className={s.root}>
      <AuthForm onSubmit={onSubmit} errors={errors} loading={loading} withName/>
    </div>
  );
};

export default RegisterPage;
