import s from "./Profile.module.css";
import React, {FC} from "react";
import logo from '../../assets/logo.png';
import {useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";
import {MEDIA_SERVER} from "../../constants";

const Profile: FC = () => {

  const userProfile = useAppSelector((state: RootState) => state.user.profile);

  const enableNotifications = () => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
      return;
    }

    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        new Notification("Notifications will be shown here!");
      }
    });
  }

  return (
    <div className={s.root}>
      <button className={s.button} onClick={enableNotifications}>
        enable notifications
      </button>
      <img src={logo} alt="logo" width={500} height={500}/>
      <div className={s.content}>
        <div className={s.row}>
          <div className={s.key}>
            name
          </div>
          <div className={s.value}>
            {userProfile.name}
          </div>
        </div>
        <div className={s.row}>
          <div className={s.key}>
            some random link
          </div>
          <div className={s.value}>
            <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">Get money now!!!</a>
          </div>
        </div>
        <div className={s.row}>
          <div className={s.key}>
            last name
          </div>
          <div className={s.value}>
            {userProfile.lastName}
          </div>
        </div>
        <div className={s.row}>
          <div className={s.key}>
            birthdate
          </div>
          <div className={s.value}>
            {userProfile.birthDate}
          </div>
        </div>
        <div className={s.row}>
          <div className={s.key}>
            email
          </div>
          <div className={s.value}>
            {userProfile.email}
          </div>
        </div>
        <div className={s.row}>
          <div className={s.key}>
            avatar
          </div>
          {userProfile.avatar && (
            <img
              src={`${MEDIA_SERVER}${userProfile.avatar}`}
              alt="avatar"
              width="80px"
              height="80px"
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Profile;
