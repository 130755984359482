import {useAppDispatch} from "../../app/hooks";
import {changeNetworkStatus} from "../../features/app/appSlice";
import {useEffect} from "react";

const useOnlineIndicator = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    function onNetworkOn() {
      dispatch(changeNetworkStatus(true));
      new Notification('online')
    }
    function onNetworkOff() {
      dispatch(changeNetworkStatus(false));
      new Notification('offline')
    }

    window.addEventListener('online', onNetworkOn)
    window.addEventListener('offline', onNetworkOff)

    return () => {
      window.removeEventListener('online', onNetworkOn)
      window.removeEventListener('offline', onNetworkOff)
    }
  }, [])
}

export { useOnlineIndicator }
