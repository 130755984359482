import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import s from './CardPage.module.css';
import EditCard from '../EditCard/EditCard';
import { useAppSelector } from '../../app/hooks';
import { routes } from '../../constants';
import CreateCard from '../CreateCard/CreateCard';
import {selectNoteById} from "../../features/notes/selectors";

const CardPage = ({ create }: { create?: boolean }) => {
  const params = useParams();
  const navigate = useNavigate();

  const note = useAppSelector(state => selectNoteById(state, Number(params.cardId)));

  useEffect(() => {
    if (!(create || note)) {
      navigate(routes.home);
    }
  }, [note, create]);

  return (
    <div className={s.root}>
      {note ? 'Card info' : 'New card'}
      {create && <CreateCard />}
      {note && <EditCard note={note} />}
    </div>
  );
};

export default React.memo(CardPage);
