import {all, call, put, takeLatest} from 'redux-saga/effects';
import {
  loginFailed,
  loginSuccess,
  profileFailed,
  profileRequest,
  profileSuccess,
  registerFailed,
  registerSuccess
} from "../features/user/userSlice";
import {AuthData} from "../Components/AuthForm/AuthData.interface";
import {fetchUser, login, loginData, ProfileData, register, RegisterData, ResponseData} from "./api";
import axios from 'axios';

export interface UserData {
  email: string,
  name: string,
  id: number,
  role: string,
}

interface Action {
  type: string,
}

function* watchLoginStart() {
  yield takeLatest(
    "user/loginRequest",
    requestLoginData
  );
}

interface loginRequestAction extends Action {
  payload: AuthData
}

function* requestLoginData({payload}: loginRequestAction) {
  const {email, password} = payload;
  try {
    const response: ResponseData<loginData> = yield call(login, {email, password});
    yield put(loginSuccess(response.data.data));
    yield put(profileRequest());
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(loginFailed(error.response));
    }
  }
}

export interface RegisterForm {
  email: string,
  name: string,
  password: string,
}

export interface RegisterAction extends Action {
  payload: RegisterForm;
}

function* watchRegisterRequest() {
  yield takeLatest(
    "user/registerRequest",
    requestRegisterData
  );
}

function* requestRegisterData({payload}: RegisterAction) {
  const {email, password, name} = payload;
  try {
    const response: ResponseData<RegisterData> = yield call(register, {name, email, password});
    yield put(registerSuccess(response.data.data));
    yield put(profileSuccess(response.data.data.user));
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(registerFailed(error.response));
    }
  }
}

function* watchLoginFinished() {
  yield takeLatest('user/loginSuccess', requestProfile);
}

function* requestProfile() {
  try {
    const response: ResponseData<ProfileData> = yield call(fetchUser);
    yield put(profileSuccess(response.data.data.user));
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(profileFailed(error.response));
    }
  }
}

export default function* loginSaga() {
  yield all([
    watchLoginStart(),
    watchLoginFinished(),
    watchRegisterRequest(),
  ]);
}
