import React, {Suspense} from 'react';
import {BrowserRouter, Route, Routes,} from 'react-router-dom';
import s from './MainPage.module.css';
import CardPage from '../CardPage/CardPage';
import {routes} from '../../constants';
import LoginPage from '../LoginPage/LoginPage';
import RegisterPage from '../RegisterPage/RegisterPage';
import ProtectedRoute from '../ProtectedRoute';
import {Loading} from "../Loading/Loading";
import {Header} from "../Header/Header";
import Profile from "../Profile/Profile";
import {useOnlineIndicator} from './useOnlineIndicator';
import {useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";

const CardList = React.lazy(() => import('../CardList/CardList'));
const About = React.lazy(() => import('../About/About'));

const MainPage = () => {

  const loggedIn = useAppSelector((state: RootState) => state.user.authorization);
  useOnlineIndicator();

  return (
    <div className={s.root}>
      <BrowserRouter>
        <Header/>
        <Suspense fallback={<Loading/>}>
          <Routes>
            <Route
              path={routes.home}
              element={
                <ProtectedRoute redirectPath={routes.login} isAllowed={!!loggedIn}>
                  <CardList/>
                </ProtectedRoute>
              }
            />
            <Route path={routes.login} element={<LoginPage/>}/>
            <Route path={routes.register} element={<RegisterPage/>}/>
            <Route path={routes.edit} element={<CardPage/>}/>
            <Route path={routes.create} element={<CardPage create/>}/>
            <Route path={routes.about} element={<About/>}/>
            <Route
              path={routes.profile}
              element={
                <ProtectedRoute redirectPath={routes.login} isAllowed={!!loggedIn}>
                  <Profile/>
                </ProtectedRoute>
              }
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default MainPage;
