import {
  configureStore,
} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import throttle from 'lodash/throttle';
import notesReducer from '../features/notes/notesSlice';
import userReducer from '../features/user/userSlice';
import appReducer from '../features/app/appSlice';
import { saveState } from './localStorage';
import rootSaga  from '../sagas';

const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
  reducer: {
    notes: notesReducer,
    user: userReducer,
    app: appReducer,
  },
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

store.subscribe(throttle(() => {
  // saveState({ notes: store.getState().notes });
}, 1000));

export const action = (type: string) => store.dispatch({type})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
