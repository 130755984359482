import React from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants';
import CardForm from '../CardForm/CardForm';
import { useAppDispatch } from '../../app/hooks';
import {createNoteRequest} from "../../features/notes/notesSlice";
import {FieldValues} from "react-hook-form";

const CreateCard = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSubmit = ({title, content}: FieldValues) => {
    dispatch(createNoteRequest({title, content}))
    navigate(routes.home);
  };

  return (
    <CardForm onSubmit={onSubmit} />
  );
};

export default CreateCard;
