import {all, call, put, takeLatest} from 'redux-saga/effects'
import {
  createNoteFailed,
  createNoteSuccess,
  deleteNoteFailed,
  deleteNoteSuccess,
  getNotesFailed,
  getNotesSuccess,
  likeFailed,
  likeSuccess,
  syncNotesFailed,
  syncNotesSuccess,
  updateNoteFailed,
  updateNoteSuccess,
} from "../features/notes/notesSlice";
import {
  createNote as createNoteAPI,
  deleteNote as deleteNoteAPI,
  fetchNotes,
  likeNote as likeNoteAPI,
  NotesData,
  ResponseData,
  updateNote as updateNoteAPI
} from "./api";
import {
  createNote as createNoteInDB,
  createNotes,
  deleteNote as deleteNoteInDB,
  getNotes as getNotesFromDB,
  likeNote as likeNoteInDB,
  removeNotes,
  updateNote as updateNoteInDB,
} from "./IndexedDB";
import {NoteData} from "../Components/CardList/CardData.interface";
import axios, {AxiosResponse} from "axios";

interface Action {
  type: string,
}

function* watchSyncRequest() {
  yield takeLatest('notes/syncNotesRequest', syncNotes);
}

function uploadLocalChanges(allLocalNotes: NoteData[]) {
  const requests: Promise<AxiosResponse>[] = [];

  allLocalNotes.forEach((note) => {
    if (note.createdLocally && !note.deletedLocally) {
      const createRequest = createNoteAPI(note);
      requests.push(createRequest);

      if (note.likedLocally) {
        // like the note by its id received from the server
        createRequest.then(response => {
          const id = response.data.data.post.id;
          const likeRequest = likeNoteAPI(id);
          requests.push(likeRequest);
        })
      }
    } else {
      if (note.deletedLocally) {
        requests.push(deleteNoteAPI(note.id));
      } else {
        if (note.likedLocally) {
          requests.push(likeNoteAPI(note.id));
        }
        if (note.changedLocally) {
          requests.push(updateNoteAPI(note));
        }
      }
    }
  })

  return Promise.all(requests);
}

function* syncNotes(action: Action) {
  try {
    const allLocalNotes: NoteData[] = yield call(getNotesFromDB);
    yield call(uploadLocalChanges, allLocalNotes)

    const notesResponse: ResponseData<NotesData> = yield call(fetchNotes);
    const notes = notesResponse.data.data.posts;

    // clear the local IDB because these notes should already be sent
    // to the server and therefore be present in the notes received from the server
    yield call(removeNotes);
    // write new data in IDB
    yield call(createNotes, notes);

    yield put(syncNotesSuccess(notes));

  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(syncNotesFailed(error.response.data.errors));
    } else {
      yield put(syncNotesFailed('unknown error'));
    }
  }
}

function* watchNotesRequest() {
  yield takeLatest('notes/getNotesRequest', readNotesFromDB);
}

function* readNotesFromDB(action: Action) {
  try {
    const allNotes: NoteData[] = yield call(getNotesFromDB);
    const notDeletedItems = allNotes.filter(note => !note.deletedLocally);
    yield put(getNotesSuccess(notDeletedItems));
  } catch (error) {
    yield put(getNotesFailed(error));
  }
}

interface likeNoteAction extends Action {
  payload: NoteData
}

export interface likeNoteFailedPayload {
  id: number,
}

function* watchLikeRequest() {
  yield takeLatest('notes/likeRequest', likeNote);
}

function* likeNote(action: likeNoteAction) {
  const note = action.payload;
  try {
    const dbResponse: NoteData = yield call(likeNoteInDB, note);
    yield put(likeSuccess(dbResponse));
  } catch (error) {
    console.error('cant like note', error)
    yield put(likeFailed({id: note.id}));
  }
}

export type EditableNoteFields = Pick<NoteData, 'title' | 'content'>;

interface createNoteAction extends Action {
  payload: EditableNoteFields
}

function* watchCreateNoteRequest() {
  yield takeLatest('notes/createNoteRequest', createNote);
}

function* createNote(action: createNoteAction) {
  try {
    const dbResponse: NoteData = yield call(createNoteInDB, action.payload);
    yield put(createNoteSuccess(dbResponse));
  } catch (error) {
    console.error('cant create note', error)
    yield put(createNoteFailed({errors: 'db error'}));
  }
}

function* watchUpdateNoteRequest() {
  yield takeLatest('notes/updateNoteRequest', updateNote);
}

interface updateNoteAction extends Action {
  payload: NoteData
}

function* updateNote(action: updateNoteAction) {
  try {
    const dbResponse: NoteData = yield call(updateNoteInDB, action.payload);
    yield put(updateNoteSuccess(dbResponse));
  } catch (error) {
    console.error('cant update note', error)
    yield put(updateNoteFailed({errors: 'db error'}));
  }
}

function* watchDeleteNoteRequest() {
  yield takeLatest('notes/deleteNoteRequest', deleteNote);
}

interface deleteNoteAction extends Action {
  payload: NoteData
}

function* deleteNote(action: deleteNoteAction) {
  try {
    const dbResponse: number = yield call(deleteNoteInDB, action.payload);
    yield put(deleteNoteSuccess(dbResponse));
  } catch (error) {
    console.error('cant delete note', error)
    yield put(deleteNoteFailed({errors: 'db error'}));
  }
}

export default function* notesSaga() {
  yield all([
    watchNotesRequest(),
    watchLikeRequest(),
    watchCreateNoteRequest(),
    watchUpdateNoteRequest(),
    watchDeleteNoteRequest(),
    watchSyncRequest(),
  ]);
}
