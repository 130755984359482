import React from 'react';
import {Provider} from 'react-redux';

import MainPage from './Components/MainPage/MainPage';
import {store} from './app/store';

const App = () => (
  <Provider store={store}>
    <MainPage/>
  </Provider>
);

export default App;
