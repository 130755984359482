import {createSlice, Draft, PayloadAction} from '@reduxjs/toolkit';
import { NoteData } from '../../Components/CardList/CardData.interface';
import {NotesData, ResponseData} from "../../sagas/api";
import {likeNoteFailedPayload, EditableNoteFields} from "../../sagas/notes";

export interface Note {
  id: number,
  title: string,
  content: string,
  liked: boolean,
  totalLikes: number,
}

export interface NotesState {
  notes: NoteData[],
  isLoading: boolean,
  errors?: Record<string, string[]> | string,
}

const initialState: NotesState = {
  isLoading: false,
  notes: [],
  errors: undefined
};

function switchLike(draft: Draft<NoteData>) {
  const prevLiked = draft.liked;
  draft.liked = !draft.liked;
  draft.totalLikes += prevLiked ? -1 : 1;
}

export const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    getNotesRequest: (state) => {
      state.isLoading = true;
    },
    getNotesSuccess: (state, action: PayloadAction<NoteData[]>) => {
      state.notes = action.payload.sort((a, b) => b.id - a.id);
      state.errors = undefined;
      state.isLoading = false;
    },
    getNotesFailed: (state, action: PayloadAction<unknown>) => {
      console.error('idb error', action.payload);
      state.errors = 'IDB error';
      state.isLoading = false;
    },

    likeRequest: (state, action: PayloadAction<NoteData>) => {
      // optimistic update likes and totalLikes
      const {id} = action.payload;
      const note = state.notes.find(note => note.id === id);
      if (!note) return;
      switchLike(note);
    },
    likeSuccess: (state, action: PayloadAction<NoteData>) => {
      state.notes = state.notes.map(item => item.id === action.payload.id ? action.payload : item)
    },
    likeFailed: (state, action: PayloadAction<likeNoteFailedPayload>) => {
      // rollback like
      const { id } = action.payload
      const note = state.notes.find(note => note.id === id);
      if (!note) return;
      switchLike(note);
    },

    createNoteRequest: (state, action: PayloadAction<EditableNoteFields>) => {
      state.isLoading = false
    },
    createNoteSuccess: (state, action: PayloadAction<NoteData>) => {
      state.notes.push(action.payload);
      state.isLoading = false;
    },
    createNoteFailed: (state, action: PayloadAction<Record<string, unknown>>) => {
      // toast error
      state.isLoading = false;
    },

    updateNoteRequest: (state, action: PayloadAction<NoteData>) => {
      state.isLoading = false;
    },
    updateNoteSuccess: (state, action: PayloadAction<NoteData>) => {
      const newNote =  action.payload;
      state.notes = state.notes.map(note => note.id === newNote.id ? newNote : note);
      state.isLoading = false;
    },
    updateNoteFailed: (state, action: PayloadAction<Record<string, unknown>>) => {
      // toast error
      state.isLoading = false;
    },

    deleteNoteRequest: (state, action: PayloadAction<NoteData>) => {
      state.isLoading = true;
    },
    deleteNoteSuccess: (state, action: PayloadAction<NoteData['id']>) => {
      state.notes = state.notes.filter(note => note.id !== action.payload);
      state.isLoading = false;
    },
    deleteNoteFailed: (state, action: PayloadAction<Record<string, unknown>>) => {
      // toast error
      state.isLoading = false;
    },

    syncNotesRequest: (state) => {
      state.isLoading = true;
    },

    syncNotesSuccess: (state, action: PayloadAction<NoteData[]>) => {
      state.notes = action.payload.sort((a, b) => b.id - a.id);
      state.errors = undefined;
      state.isLoading = false;
    },
    syncNotesFailed: (state, action) => {
      state.isLoading = false;
    }
  }
});

export const {
  getNotesRequest, getNotesSuccess, getNotesFailed,
  likeRequest, likeSuccess, likeFailed,
  createNoteRequest, createNoteSuccess, createNoteFailed,
  updateNoteRequest, updateNoteSuccess, updateNoteFailed,
  deleteNoteRequest, deleteNoteSuccess, deleteNoteFailed,
  syncNotesRequest, syncNotesSuccess, syncNotesFailed,
} = notesSlice.actions

export default notesSlice.reducer;
