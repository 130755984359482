import axios, {AxiosRequestConfig, AxiosRequestHeaders, Method} from "axios";
import {API_KEY} from "../constants";
import camelizeKeys from "./camelCase.js";

export interface APIConfig {
  method: Method,
  headers?: AxiosRequestHeaders,
  data: Record<string, unknown>;
}

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Application-Key': API_KEY,
  }
});

function setJWTHeader(config: AxiosRequestConfig<APIConfig>) {
  const authHeader = window.localStorage.getItem('Authorization')
  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: authHeader
    }
  };
}

api.interceptors.request.use(setJWTHeader);

api.interceptors.response.use(
  (response) => camelizeKeys(response),
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => camelizeKeys(response),
);

export {api};
