import React, { FC } from 'react';
import {FieldValues, SubmitHandler, useForm} from 'react-hook-form';
import s from './AuthForm.module.css';
import {LoginCredentials, RegistrationCredentials} from "./AuthData.interface";

interface Props {
  onSubmit: SubmitHandler<FieldValues>,
  withName?: boolean,
  errors?: Record<string, string[]> | string,
  loading?: boolean
}

const INPUT_REGEX = /^[A-Za-z0-9.@]+$/i;

const AuthForm: FC<Props> = ({ onSubmit, withName, errors, loading }) => {
  const { register, handleSubmit, formState: { errors: inputErrors } } = useForm<LoginCredentials | RegistrationCredentials>();

  return (
    <form
      className={s.root}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={s.body}>
        {withName && (
          <input
            className={s.title}
            {...register('name', { required: true, pattern: INPUT_REGEX })}
            placeholder="name"
          />
        )}
        <input
          className={s.title}
          {...register('email', { required: true, pattern: INPUT_REGEX })}
          placeholder="email"
          defaultValue=""
        />
        <input
          className={s.text}
          {...register('password', { required: true, pattern: INPUT_REGEX })}
          placeholder={'password'}
          defaultValue=""
          type="password"
        />
      </div>
      {/*todo: display input validation errors*/}
      {!loading && errors &&(
        <div>
          {typeof errors === 'string' && 'wrong password'}
          {typeof errors === 'object' && errors?.email && 'wrong email'}
        </div>
      )}
      {loading && (
        <div>
          loading...
        </div>
      )}
      <div className={s.buttons}>
        <input type="submit" value="save"/>
      </div>
    </form>
  );
};

export default AuthForm;
