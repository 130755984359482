import {FC} from "react";

const Loading: FC = () => {
  return (
    <div>
      loading...
    </div>
  )

}

export {Loading}
