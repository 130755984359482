import React from 'react';
import s from './CardPage.module.css';
import {useAppDispatch, useAppSelector} from '../../app/hooks';
import AuthForm from '../AuthForm/AuthForm';
import {FieldValues} from "react-hook-form";
import {loginRequest} from "../../features/user/userSlice";
import {selectErrors, selectLoading} from "../../features/user/selectors";

const LoginPage = () => {
  const dispatch = useAppDispatch();
  const errors = useAppSelector(selectErrors);
  const loading = useAppSelector(selectLoading);

  const onSubmit = ({ email, password }: FieldValues) => {
    dispatch(loginRequest({email, password}))
  };

  return (
    <div className={s.root}>
      <AuthForm onSubmit={onSubmit} errors={errors} loading={loading}/>
    </div>
  );
};

export default LoginPage;
