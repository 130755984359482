import {RootState} from "../../app/store";

const USER_ROLES = {
  admin: 'admin',
  user: 'user',
}

const selectIsAdminUser = (state: RootState) => state.user.profile?.role === USER_ROLES.admin

const selectLoading = (state: RootState) => state.user.isLoading;
const selectErrors = (state: RootState) => state.user.errors;

export {selectIsAdminUser, selectLoading, selectErrors}
