import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface userState {
  online: boolean
}

const initialState: userState = {
  online: true,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeNetworkStatus: (state, action: PayloadAction<boolean>) => {
      state.online = action.payload;
    },
  },
});

export const {
  changeNetworkStatus
} = appSlice.actions

export {appSlice};
export default appSlice.reducer;
