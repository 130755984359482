import { all } from 'redux-saga/effects';
import notesSaga from "./notes";
import loginSaga from "./user";

export default function* rootSaga() {
  yield all([
    loginSaga(),
    notesSaga(),
  ]);
}
