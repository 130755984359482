import React from 'react';
import {FieldValues, SubmitHandler, useForm} from 'react-hook-form';
import s from './CardForm.module.css';
import {NoteData} from "../CardList/CardData.interface";

interface Props {
  noteData?: NoteData
  onSubmit: SubmitHandler<FieldValues>
  onDelete?: () => void,
}

const INPUT_REGEX = /^[A-Za-z0-9 .,?!]+$/i;

export function CardForm({ noteData, onDelete , onSubmit}: Props) {
  const { register, handleSubmit, formState: { errors } } = useForm<Pick<NoteData, 'title' | 'content'>>();

  return (
    <form className={s.root}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={s.body}>
        <input
          className={s.title}
          {...register('title', { required: true, pattern: INPUT_REGEX })}
          defaultValue={noteData?.title || ''}
          placeholder="title"
        />
        <textarea
          className={s.text}
          {...register('content', { required: true, pattern: INPUT_REGEX })}
          defaultValue={noteData?.content || ''}
          placeholder={'some text'}
          rows={5}
        />
      </div>
      <div>
        {errors.title?.type === 'required' && 'Title is required'}
        {errors.title?.type === 'pattern' && "Title mustn't contain special characters"}
      </div>
      <div>
        {errors.content?.type === 'required' && 'Text is required'}
        {errors.content?.type === 'pattern' && "Text mustn't contain special characters"}
      </div>
      <div className={s.buttons}>
        <input type="submit" value="save"/>
        {onDelete && (
          <button onClick={onDelete}>
            delete
          </button>
        )}
      </div>
    </form>
  );
}

export default CardForm;
