import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {loginData, ResponseData} from "../../sagas/api";
import {RegisterForm, UserData} from "../../sagas/user";

export interface userState {
  authorization: string,
  errors?: Record<string, string[]> | string,
  isLoading: boolean,
  profile: {
    name?: string,
    lastName?: string,
    birthDate?: string,
    avatar?: string,
    email?: string,
    role?: string,
    id?: number,
  }
}

const localUserData = JSON.parse(localStorage.getItem('user') || '{}');
const authData = localStorage.getItem('Authorization');
const initialState: userState = {
  isLoading: false,
  errors: undefined,
  authorization: authData || '',
  profile: localUserData,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginRequest: (state, action: PayloadAction<{ email: string, password: string }>) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action: PayloadAction<loginData>) => {
      const {token} = action.payload;
      if (token) {
        state.authorization = `Bearer ${token}`;
        state.errors = undefined;
        localStorage.setItem('Authorization', state.authorization);
      }
      state.isLoading = false;
    },
    loginFailed: (state, action: PayloadAction<ResponseData<loginData>>) => {
      state.errors = action.payload.data.errors;
      state.isLoading = false;
    },

    registerRequest: (state, payload: PayloadAction<RegisterForm>) => {
      state.isLoading = true;
    },
    registerSuccess: (state, action: PayloadAction<loginData>) => {
      const {token} = action.payload;
      if (token) {
        state.authorization = `Bearer ${token}`;
        state.errors = undefined;
        localStorage.setItem('Authorization', state.authorization);
      }
      state.isLoading = false;
    },
    registerFailed: (state, action: PayloadAction<ResponseData<loginData>>) => {
      state.errors = action.payload.data.errors;
      state.isLoading = false;
    },

    profileRequest: (state) => {
      state.isLoading = true;
    },
    profileSuccess: (state, action: PayloadAction<UserData>) => {
      state.profile = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload));
      state.isLoading = false;
      state.errors = undefined;
    },
    profileFailed: (state, action: PayloadAction<ResponseData<{}>>) => {
      state.errors = action.payload.data.errors;
      state.isLoading = false;
    },
  },
});

export const {
  loginRequest, loginSuccess, loginFailed,
  registerRequest, registerSuccess, registerFailed,
  profileRequest, profileSuccess, profileFailed
} = userSlice.actions

export {userSlice};
export default userSlice.reducer;
