import {AxiosError} from 'axios';
import {API_ROUTES, API_URL} from "../constants";
import {AuthData} from "../Components/AuthForm/AuthData.interface";
import {api} from "../helpers/apiHelper";
import {NoteData} from "../Components/CardList/CardData.interface";
import {EditableNoteFields} from "./notes";
import {UserData} from "./user";

export interface ResponseData<T> {
  data: {
    success: boolean,
    errors: Record<string, string[]> | string,
    data: T
  }
}

export interface loginData {
  token?: string
}

export interface RegisterData extends loginData {
  user: UserData
}

export interface ProfileData {
  user: UserData
}

export interface NotesData {
  posts: NoteData[]
}

export function login({email, password}: AuthData) {
  return api.post(`${API_URL}${API_ROUTES.login}`, {email, password})
}

export async function register({name, email, password}: AuthData): Promise<ResponseData<RegisterData> | AxiosError['response']> {
  return api.post(API_ROUTES.register, {name, email, password})
}

export function fetchUser() {
  return api.get(API_ROUTES.user)
}

export async function fetchNotes() {
  return api.get(API_ROUTES.posts)
}

export async function likeNote(id: number) {
  return api.post(`${API_ROUTES.posts}/${id}${API_ROUTES.like}`);
}

export async function createNote(formData: EditableNoteFields) {
  return api.post(`${API_ROUTES.posts}`, formData);
}

export async function updateNote(noteData: NoteData) {
  return api.put(`${API_ROUTES.posts}/${noteData.id}`, noteData);
}

export async function deleteNote(id: number) {
  return api.delete(`${API_ROUTES.posts}/${id}`);
}
