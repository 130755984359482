import s from "./Header.module.css";
import {Link} from "react-router-dom";
import {routes} from "../../constants";
import React, {FC} from "react";
import {useAppSelector} from "../../app/hooks";
import {RootState} from "../../app/store";

const Header: FC = () => {

  const online = useAppSelector((state: RootState) => state.app.online);

  return (
    <div className={s.root}>
      <Link className={s.item} to={routes.home}>home</Link>
      <Link className={s.item} to={routes.login}>login</Link>
      <Link className={s.item} to={routes.register}>register</Link>
      <Link className={s.item} to={routes.about}>about</Link>
      <Link className={s.item} to={routes.profile}>profile</Link>
      <div>
        working {online ? 'online' : 'offline'}
      </div>
    </div>
  )
}

export {Header};
