import React, {ReactElement} from 'react';
import {Navigate, Outlet} from "react-router-dom";
import {routes} from "../constants";

export interface Props {
  isAllowed: boolean,
  redirectPath: string,
  children: ReactElement
}

const ProtectedRoute = ({
  isAllowed,
  redirectPath = `${routes.login}`,
  children,
}: Props) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace/>;
  }

  return children ? children : <Outlet/>;
};

export default ProtectedRoute;
