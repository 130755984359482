import {DB_NAME, DB_VERSION, NOTES_STORE} from "../constants";
import {EditableNoteFields} from "./notes";
import {NoteData} from "../Components/CardList/CardData.interface";


function initDB(): Promise<IDBDatabase> {
  return new Promise((resolve, reject) => {

    let openRequest = indexedDB.open(DB_NAME, DB_VERSION);

    openRequest.onupgradeneeded = function () {
      // ...perform initialization...
      let db = openRequest.result;
      if (!db.objectStoreNames.contains('notes')) {
        db.createObjectStore('notes', {keyPath: 'id'});
      }
    };

    openRequest.onerror = function () {
      console.error("Error", openRequest.error);
      reject(openRequest.error);
    };

    openRequest.onsuccess = function () {
      const db = openRequest.result;
      db.onversionchange = function () {
        db?.close();
        alert("Database is outdated, please reload the page.")
      };
      resolve(db);
    };
  })
}

const dbRequest = initDB();

function createNote(noteData: EditableNoteFields) {
  return new Promise<NoteData>(async (resolve, reject) => {
    const db = await dbRequest;
    const data = {
      liked: false,
      totalLikes: 0,
      ...noteData,
      id: 9000000000000000 + Date.now(),

      createdLocally: true,
      likedLocally: false,
      changedLocally: false,
      deletedLocally: false
    }
    let transaction = db.transaction(NOTES_STORE, "readwrite")
    let notesStore = transaction.objectStore(NOTES_STORE);

    let request: IDBRequest<IDBValidKey> = notesStore.add(data);

    request.onsuccess = () => {
      resolve(data);
    }

    request.onerror = (e) => {
      reject(e);
    }
  })
}

function removeNotes() {
  return new Promise<Boolean>(async (resolve, reject) => {
    const db = await dbRequest;
    let transaction = db.transaction(NOTES_STORE, "readwrite")
    let notesStore = transaction.objectStore(NOTES_STORE);

    notesStore.clear();

    transaction.oncomplete = () => {
      resolve(true);
    }
    transaction.onerror = (e) => {
      reject(e);
    }
  })
}

function createNotes(notes: NoteData[]) {
  return new Promise<Boolean>(async (resolve, reject) => {
    const db = await dbRequest;
    let transaction = db.transaction(NOTES_STORE, "readwrite")
    let notesStore = transaction.objectStore(NOTES_STORE);

    notes.forEach(note => {
      notesStore.add(note);
    })

    transaction.oncomplete = () => {
      resolve(true);
    }

    transaction.onerror = (e) => {
      reject(e);
    }
  })
}

function updateNote(noteData: NoteData) {
  return new Promise<NoteData>(async (resolve, reject) => {
    const db = await dbRequest;
    let transaction = db.transaction(NOTES_STORE, "readwrite")
    let notesStore = transaction.objectStore(NOTES_STORE);

    const newNote = {
      ...noteData,
      changedLocally: true
    }

    let request: IDBRequest<IDBValidKey> = notesStore.put(newNote);

    request.onsuccess = () => {
      resolve(newNote);
    }

    request.onerror = (e) => {
      reject(e);
    }
  })
}

function likeNote(note: NoteData) {
  return new Promise<NoteData>(async (resolve, reject) => {
    const db = await dbRequest;
    let transaction = db.transaction(NOTES_STORE, "readwrite")
    let store = transaction.objectStore(NOTES_STORE);

    const newNote = {
      ...note,
      liked: !note.liked,
      likedLocally: !note.likedLocally
    }

    let writeRequest: IDBRequest = store.put(newNote);

    writeRequest.onsuccess = () => {
      resolve(newNote);
    }

    writeRequest.onerror = (e) => {
      reject(e);
    }
  })
}

function getNotes() {
  return new Promise<NoteData[]>(async (resolve, reject) => {
    const db = await dbRequest;
    let transaction = db.transaction(NOTES_STORE, "readonly")
    let notesStore = transaction.objectStore(NOTES_STORE);

    let request: IDBRequest<NoteData[]> = notesStore.getAll();
    request.onsuccess = () => {
      if (request.result !== undefined) {
        resolve(request.result);
      }
    }
    request.onerror = (e) => {
      reject(e);
    }
  })
}

function deleteNote(note: NoteData) {
  return new Promise<NoteData>(async (resolve, reject) => {
    const db = await dbRequest;
    let transaction = db.transaction(NOTES_STORE, "readwrite")
    let notesStore = transaction.objectStore(NOTES_STORE);

    const newNote = {
      ...note,
      deletedLocally: true
    }

    let request: IDBRequest = notesStore.put(newNote);

    request.onsuccess = () => {
      resolve(newNote);
    }

    request.onerror = (e) => {
      reject(e);
    }
  })
}

export {createNote, updateNote, getNotes, deleteNote, likeNote, createNotes, removeNotes}
