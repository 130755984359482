export enum routes {
  home = '/',
  create = '/create',
  note = '/note',
  edit = '/note/:cardId',
  login = '/login',
  register = '/register',
  about = '/about',
  profile = '/profile',
}

export const API_ROUTES = {
  login: '/login',
  user: '/user',
  register: '/register',
  posts: '/posts',
  like: '/like'
}


export const DB_NAME = 'myDB';
export const NOTES_STORE = 'notes';
export const DB_VERSION = 1;

export const API_KEY = process.env.REACT_APP_API_KEY || '';
export const API_URL = process.env.REACT_APP_API_URL || '';
export const MEDIA_SERVER = process.env.REACT_APP_MEDIA_SERVER || '';
