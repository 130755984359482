import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { NoteData } from '../CardList/CardData.interface';
import { routes } from '../../constants';
import CardForm from '../CardForm/CardForm';
import { useAppDispatch } from '../../app/hooks';
import {deleteNoteRequest, updateNoteRequest} from "../../features/notes/notesSlice";
import {FieldValues} from "react-hook-form";

interface EditCardProps {
  note: NoteData,
}

const EditCard: FC<EditCardProps> = ({ note }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = (newData: FieldValues) => {
    dispatch(updateNoteRequest({ ...note, ...newData}))
    navigate(routes.home);
  };

  const onDelete = () => {
    dispatch(deleteNoteRequest(note));
    navigate(routes.home);
  };

  return (
    <CardForm
      noteData={note}
      onSubmit={onSubmit}
      onDelete={onDelete}
    />
  );
};

export default EditCard;
